import * as Sentry from '@sentry/astro';

Sentry.init({
  dsn: 'https://650717f3e917ec94e1907b341747f4d3@o341960.ingest.us.sentry.io/4508810522001408',
  
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: 0.1,
});
